import React,{ lazy } from 'react';


// Load potentially render blocking stylesheets
// overkill for the start
import './index.css';
import {Select} from "@radix-ui/react-select";


const ReactDOM  = await import('react-dom/client');


// load things that we need....eventually
const preLoad = async () => {
    const fontAwesome = import("./fontawesome-pro-6.5.2-web/css/all.min.css");
    const styles = import("@radix-ui/themes/styles.css");
    const getThemePanel = () => {
        const {ThemePanel} = import("@radix-ui/themes");
        return ThemePanel;
    }
    const getRadixUi = () => {



        // theme panel workaround until I fix it correctly

        return [
        () => { const {AlertDialog} = import("@radix-ui/themes"); return AlertDialog; },
        () => { const {Checkbox} = import("@radix-ui/themes"); return Checkbox; },
            ,
        () => { const {ContextMenu} = import("@radix-ui/themes"); return ContextMenu; },
            ,
        () => { const {Dropdown} = import("@radix-ui/themes"); return Dropdown; },
            ,
        () => { const {DropdownItem} = import("@radix-ui/themes"); return DropdownItem; },
            ,
        () => { const {DropdownMenu} = import("@radix-ui/themes"); return DropdownMenu; },
            ,
        () => { const {HoverCard} = import("@radix-ui/themes"); return HoverCard; },
            ,
        () => { const {Menu} = import("@radix-ui/themes"); return Menu; },
            ,
        () => { const {MenuItem} = import("@radix-ui/themes"); return MenuItem; },
            ,
        () => { const {MenuList} = import("@radix-ui/themes"); return MenuList; },
            ,
        () => { const {MenuCard} = import("@radix-ui/themes"); return MenuCard; },
            ,
        () => { const {NavigationMenu} = import("@radix-ui/themes"); return NavigationMenu; },
            ,
        () => { const {RadioGroup} = import("@radix-ui/themes"); return RadioGroup; },
            ,
        () => { const {ScrollArea} = import("@radix-ui/themes"); return ScrollArea; },
            ,
        () => { const {Select} = import("@radix-ui/themes"); return Select; },
            ,
        () => { const {Slider} = import("@radix-ui/themes"); return Slider; },
            ,
        () => { const {Toolbar} = import("@radix-ui/themes"); return Toolbar; },
            ,
        () => { const {Tooltip} = import("@radix-ui/themes"); return Tooltip; },
            ,
        () => { const {Popover} = import("@radix-ui/themes"); return Popover; },
        ]
    };


    //
    const GQL = import("graphql/language")
    const QRIOUS = import("qrious")
    // import("@radix-ui/themes");
    // trying to trick these into importing but we don't *need* them
    // const fauxImports = {
    //     "@floating-ui/core": () => import("@floating-ui/core"),
    //     "react-remove-scroll": () => import("react-remove-scroll"),
    //     "@radix-ui/react-select": () => import("@radix-ui/react-select"),
    //     "@radix-ui/react-slider": () => import("@radix-ui/react-slider"),
    //     "@radix-ui/react-scroll-area": () => import("@radix-ui/react-scroll-area"),
    //     "@radix-ui/themes/src/components/theme-panel": () => import("@radix-ui/themes/src/components/theme-panel.tsx"),
    //
    // };
    const getRadix = async () => {
        const { Theme } = await import("@radix-ui/themes");
        return {Theme};
    }
    return { QRIOUS,GQL, Select, getRadix, getRadixUi, styles, fontAwesome, getThemePanel } //fauxImports;
};
const things = await preLoad();

await things.getRadix();
console.log(things);
const App = lazy(async ()=> {


    const _app = import('./App');
    return _app;
});
// import reportWebVitals from './reportWebVitals';
// const React = import('react');
// const ReactDOM = await import('react-dom/client');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
